import { filterNonNull } from '../utils.js';

export const createUpdateState = (newState) => {
  return {
    items: [...newState],
    itemSubCategoryName: filterNonNull([...new Set(newState?.map(item => item.itemSubCategoryName))]),
    cartItems: filterNonNull([...new Set(newState?.map(item => item.itemAmount > 0 ? item : null))]),
    allNewItems: filterNonNull([...new Set(newState?.map(item => item.itemNew > 0 ? item : null))]),
    allCampaigns: filterNonNull([...new Set(newState?.map(item => item.itemCampaign > 0 ? item : null))]),
    allSold: filterNonNull([...new Set(newState?.map(item => item.itemSold > 0 ? item : null))]),
    notSold: filterNonNull([...new Set(newState?.map(item => item.itemSold === 0 ? item : null))]),
    allFavorites: filterNonNull([...new Set(newState?.map(item => item.itemFavorite > 0 ? item : null))]),
  };
};
