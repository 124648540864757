import {URL as NEWURL} from '../constants';
import {VENDORID as NEWVENDORID} from '../constants';

export default function useFetchPDFFile(invoiceNr) {

    const url = NEWURL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 3002,
            "vendorId": NEWVENDORID,
            "token": sessionStorage.getItem('token'),
            "moduleNr":143,
            "recordNr":invoiceNr
        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                console.log(requestOptions)

                return json;
            }
            else return json;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
