import React, { Component } from "react";
import { CarouselData } from "./CarouselData";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Swipe from "react-easy-swipe";

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      paused: false,
      intervalId: 0,
      url: "noImage.jpg"
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidMount() {

    const newIntervalId = setInterval(() => {
      if (this.state.paused === false) {
        let newSlide =
          this.state.currentSlide === CarouselData.length - 1
            ? 0
            : this.state.currentSlide + 1;
        this.setState(prevState => {
          return {
            ...prevState,
            currentSlide: newSlide,
          };
        });
      }
    }, 3000);

    this.setState(prevState => {
      return {
        ...prevState,
        intervalId: newIntervalId,
      };
    });
  }
  checkAfterCurrentSlide2 = (c) => {
    let x
    if (c === CarouselData.length - 2) { x = 0; }
    else if (c === CarouselData.length - 1) { x = 1; }
    else { x = c + 2; }

    return x
  }

  checkAfterCurrentSlide1 = (c) => {
    let x

    if (c === CarouselData.length - 1) { x = 0; }
    else { x = c + 1; }

    return x
  }

  nextSlide = () => {
    let newSlide =
      this.state.currentSlide === CarouselData.length - 1
        ? 0
        : this.state.currentSlide + 1;
    this.setState({ currentSlide: newSlide });
  };

  prevSlide = () => {
    let newSlide =
      this.state.currentSlide === 0
        ? CarouselData.length - 1
        : this.state.currentSlide - 1;
    this.setState({ currentSlide: newSlide });
  };

  setCurrentSlide = (index) => {
    this.setState({ currentSlide: index });
  };

  render() {
    return (

      <div className="w-full flex overflow-hidden relative h-64">
        <AiOutlineLeft onMouseEnter={() => {
          this.setState({ paused: true });
        }}
          onMouseLeave={() => {
            this.setState({ paused: false });
          }}
          onClick={this.prevSlide}
          className="absolute left-0 text-3xl inset-y-1/2 text-slate-500 cursor-pointer"
        />

        <Swipe onSwipeLeft={this.nextSlide} onSwipeRight={this.prevSlide} className="flex flex-row justify-evenly h-full w-full bg-white">
          <div >
            {CarouselData.map((slide, index) => {
              return (

                <div key={index} className="flex">

                  <img
                    src={slide.image}
                    alt="Güncel kampanyalarımız burada"

                    className={index === this.state.currentSlide
                      ? "flex object-cover "
                      : "hidden"}
                    onMouseEnter={() => {
                      this.setState({ paused: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ paused: false });
                    }}
                    onError={(e) => {
                      e.currentTarget.onerror = null; // prevents looping
                      e.currentTarget.src = this.state.url;
                    }}
                    />

                </div>


              );
            })
            }</div><div>
            {CarouselData.map((slide, index) => {
              return (

                <div key={index} className="flex">

                  <img
                    src={slide.image}
                    alt="Güncel kampanyalarımız burada"

                    className={index === (this.checkAfterCurrentSlide1(this.state.currentSlide))
                      ? "flex object-cover "
                      : "hidden"}
                    onMouseEnter={() => {
                      this.setState({ paused: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ paused: false });
                    }}
                    onError={(e) => {
                      e.currentTarget.onerror = null; // prevents looping
                      e.currentTarget.src = this.state.url;
                    }}
                    />

                </div>


              );
            })
            }</div><div>
            {CarouselData.map((slide, index) => {
              return (

                <div key={index} className="flex ">

                  <img
                    src={slide.image}
                    alt="Güncel kampanyalarımız burada"

                    className={index === (this.checkAfterCurrentSlide2(this.state.currentSlide))
                      ? "flex object-cover"
                      : "hidden"}
                    onMouseEnter={() => {
                      this.setState({ paused: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ paused: false });
                    }}
                    onError={(e) => {
                      e.currentTarget.onerror = null; // prevents looping
                      e.currentTarget.src = this.state.url;
                    }}
                  />
                  <div className="absolute -z-10 bg-gradient-to-l from-white via-black to-black h-[70vh] lg:h-[80vh] w-full" />
                </div>


              );
            })
            }</div>
        </Swipe >

        <div className="absolute w-full flex justify-center bottom-0">
          {CarouselData.map((element, index) => {
            return (
              <div
                className={
                  index === this.state.currentSlide
                    ? "h-2 w-2 bg-white rounded-full mx-2 mb-2 cursor-pointer"
                    : "h-2 w-2 bg-slate-500 rounded-full mx-2 mb-2 cursor-pointer"
                }
                key={index}
                onClick={() => {
                  this.setCurrentSlide(index);
                }}
              ></div>
            );
          })}
        </div>

        <AiOutlineRight
          onClick={this.nextSlide} onMouseEnter={() => {
            this.setState({ paused: true });
          }}
          onMouseLeave={() => {
            this.setState({ paused: false });
          }}
          className="absolute right-0 text-3xl inset-y-1/2 text-slate-500 cursor-pointer"
        />
      </div >

    );
  }
}

export default Carousel;