import React from "react";

export default class CounterBtn extends React.Component {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        className={`${this.props.className}
                 flex w-full justify-center text-slate-800 mb-1 text-xs sm:text-lg select-none
                ">`}
      >
        {this.props.children}
      </button>
    );
  }
}
