import React, { useState, useContext } from "react";
import LoginToWebshop from "../components/LoginToWebshop";
import { ReactComponent as LogoutIcon } from '../assets/pic/logout.svg';
import fetchItemsFromAPI from "../fetch/fetchItemsFromAPI";
import useFetchDataFromAPI from "../fetch/useFetchDataFromAPI";
import { LanguageContext, Text } from "../languages/Language";

//import ReactDOM from "react-dom";
//import PropTypes from 'prop-types';

function setToken() {
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    return tokenString;
}

export default function RegisterModal({ showModal, setShowModal,onLogoutDeleteItemsAndCategories, onGetCategories, onGetItems, parentHandleToken, children }) {
    const [customerCode, setCustomerCode] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
 //   const [showModal, setShowModal] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const languageContext = useContext(LanguageContext);
    const [differentBillingAddress, setDifferentBillingAddress] = useState(false);

    var token = getToken();


    function handleLogout() {
        window.location.reload();
        setCustomerCode("")
        setEmail("")
        setPassword("")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('discRate');
        sessionStorage.removeItem('slipRef');
        sessionStorage.removeItem('items');
        sessionStorage.removeItem('allCategories');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('customerCode');
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        //localStorage.clear();
        setToken("");
        setIsSubmitted(false);
        parentHandleToken(false);
        onLogoutDeleteItemsAndCategories()
    }


    const renderForm = (
        <>
            <button
                className="text-white  items-center mx-2
                justify-center
                text-sm font-bold  hover:opacity-75"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {children ? children :
                    <Text tid="Register" />}
            </button>
            {showModal ? (
                <>

                    <div
                        className="text-slate-600 h-5/6 fade In justify-center items-center flex overflow-hidden self-center fixed inset-0 z-50
                        outline-none focus:outline-none"
                    >
                        <div className="relative flex my-6 mx-auto lg:w-2/3 p-2 h-full">
                            {/*content*/}
                            <div className="p-4  border-0 rounded-lg text-company-primary-color shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                {/*header*/}
                                <div className="flex justify-between p-2 border-b border-solid border-slate-200 rounded-t items-center">
                                    <h3 className="text-lg font-semibold ">
                                        Kaydol
                                    </h3>
                                    <div className='flex w-full justify-end mr-6'>

                                    </div>
                                    <button
                                        className="flex items-center justify-center ml-auto border-0 text-company-primary-color opacity-50 float-right text-xl
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent h-7 w-7 text-xl inline-block align-text-top outline-none focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                </div>

                                {/*body*/}
                                <form className="overflow-y-auto">

  <div className="grid grid-cols-1 gap-4 m-1">
    <div>
      <label className="block mb-2">Firma Ünvanı</label>
      <input type="text" className="w-1/2 p-2 border border-gray-300 rounded" required />
    </div>
    <div>
      <div className="flex space-x-4">
        <label>
          <input type="radio" name="anrede" value="Bay" className="mr-2" />
          Bay
        </label>
        <label>
          <input type="radio" name="anrede" value="Bayan" className="mr-2" />
          Bayan
        </label>
      </div>
    </div>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block mb-2">Adı</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
      <div>
        <label className="block mb-2">Soyadı</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
    </div>
    <div>
      <label className="block mb-2">Ülke</label>
      <select className="w-1/2 p-2 border border-gray-300 rounded">
        <option>Almanya</option>
        <option>Hollanda</option>
        <option>Belçika</option>
      </select>
    </div>
    <div className="grid grid-cols-4 gap-4">
      <div>
        <label className="block mb-2">Posta Kodu</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
      <div>
        <label className="block mb-2">Şehir</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
      <div>
        <label className="block mb-2">Cadde</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
      <div>
        <label className="block mb-2">Numara</label>
        <input type="text" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
    </div>
    <div>
      <label className="block mb-2">Vergi Numarası</label>
      <input type="text" className="w-1/2 p-2 border border-gray-300 rounded" required/>
    </div>
    <div>
      <label className="block mb-2">Telefon</label>
      <input type="text" className="w-1/2 p-2 border border-gray-300 rounded" required/>
    </div>
    <div>

    <div className="mt-4">
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" onChange={(e) => setDifferentBillingAddress(e.target.checked)} />
                    Sevkiyat Adresi
                  </label>
                </div>
                {differentBillingAddress && (
                  <div className="mt-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block mb-2">Adı</label>
                          <input type="text" className="w-full p-2 border border-gray-300 rounded" required />
                        </div>
                        <div>
                          <label className="block mb-2">Soyadı</label>
                          <input type="text" className="w-full p-2 border border-gray-300 rounded" required />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block mb-2">Posta Kodu / Şehir</label>
                          <input type="text" className="w-full p-2 border border-gray-300 rounded" required />
                        </div>
                        <div>
                          <label className="block mb-2">Cadde / Numara</label>
                          <input type="text" className="w-full p-2 border border-gray-300 rounded" required />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

      <label className="block mb-2">E-Mail</label>
      <input type="email" className="w-1/2 p-2 border border-gray-300 rounded" required/>
    </div>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block mb-2">Şifre</label>
        <input type="password" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
      <div>
        <label className="block mb-2">Şifre Tekrar</label>
        <input type="password" className="w-full p-2 border border-gray-300 rounded" required/>
      </div>
    </div>
    <button type="submit" className="w-full bg-company-primary-light text-white p-2 rounded">
      Kaydol
    </button>
  </div>
</form>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

    const renderLogout = (
        <button
            className="flex w-28 lg:w-32 items-center
            rounded-md border border-transparent
            text-base font-medium text-company-primary-color shadow-sm hover:opacity-75"
            type="button"
            onClick={() => handleLogout()}
        >
            <LogoutIcon className="w-6 h-6 fill-company-primary-color mr-2" />
            <Text tid="SignOff" />
        </button>
    );

    return (
        <div>
            {
                isSubmitted
                    ? renderLogout
                    : sessionStorage.getItem('token')
                        ? renderLogout
                        : renderForm
            }
        </div>
    );
}

