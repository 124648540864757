import React from 'react'
import { Text } from '../languages/Language';

export default function Contact(props) {

    return (
        <>
            <button
                className="flex items-center
        justify-center "
                type="button"
                onClick={() => props.ShowContact(true)}
            >
                <Text tid="Contact" />
            </button>

        </>
    )
}