import React from 'react'
import { Text } from '../languages/Language';
import {URL as NEWURL} from '../constants';
import {VENDORID as NEWVENDORID} from '../constants';
import { ReactComponent as OpenIcon } from '../assets/pic/open.svg';

export default function SlipGetSaved(props) {
    const [showModal, setShowModal] = React.useState(false);

    function onSlipOpen(actualSlipSave) {
        // const slipRef = sessionStorage.getItem('slipRef');
        setShowModal(false)


        props.onClick();
        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 2016,
                "token": sessionStorage.getItem('token'),
                "actualSlipRef": sessionStorage.getItem('slipRef'),
                "slipRef": props.slipRef,
                "actualSlipSave": actualSlipSave,
                "vendorId": NEWVENDORID
            })
        };

        const fetchData = async () => {
            try {
                sessionStorage.removeItem('slipRef');
                sessionStorage.removeItem('items');

                const response = await fetch(url, requestOptions);
                const json = await response.json();
                if (json.resCode === 200) {
                    console.log(json)
                    sessionStorage.setItem('slipRef', json.slip[0]?.slipRef);
                    sessionStorage.setItem('items', JSON.stringify(json.slipLines));
                    sessionStorage.setItem('Expl1', json.slip[0]?.erpGenExpl1);
                    sessionStorage.setItem('Expl2', json.slip[0]?.erpGenExpl2);
                    props.onLoadSavedSlip();
                }
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }

    return (
        <>
            <button
                className="flex items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                type="button" onClick={() => setShowModal(true)}
            >
                <OpenIcon className="w-6 h-6 fill-white"/>
            </button>
            {showModal ? (

                <>
                    <div
                        className="fade In justify-center items-center flex overflow-x-hidden
                        overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className="relative w-auto my-6 mx-auto max-w-3xl"
                        >
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                                {/*header*/}
                                <div
                                    className="flex items-start justify-start p-5 border-b border-solid border-slate-200 rounded-t"
                                >
                                    <button
                                        className="p-1 bg-transparent border-0 text-black opacity-50 float-right text-3xl
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span
                                            className="bg-transparent text-black h-6 w-6 text-xl block outline-none focus:outline-none"
                                        >
                                            &#10006;
                                        </span>
                                    </button>
                                    <h3
                                        className="text-lg font-semibold mt-1"
                                    >
                                        <Text tid="SlipSave" />
                                    </h3>

                                </div>
                                {/*body*/}
                                <div
                                    className="relative p-6 flex-auto"
                                >
                                    <div
                                        className="my-4 text-slate-500 text-lg leading-relaxed">
                                        <Text tid="SlipSaveText" />
                                        <div className='flex flex-row justify-center mt-6 gap-2'>

                                        <button
                                            className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                            type="button"
                                            onClick={() => onSlipOpen(1)}
                                        >
                                            <Text tid="Yes" />
                                        </button>
                                        <button
                                            className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                            type="button"
                                            onClick={() => onSlipOpen(0)}
                                        >
                                            <Text tid="No" />
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-50 fixed inset-0 z-40 bg-black"
                    >
                    </div>
                </>

            )
                : null
            }
        </>
    )
}