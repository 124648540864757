export const CarouselData = [
    {
      id:0,
      image:
        "./brands/bal_n.jpg",
    },
    {
      id:1,
      image:
        "./brands/destan_n.jpg",
    },
    {
      id:2,
      image:
        "./brands/kofte_n.jpg",
    },
    {
      id:3,
      image:
        "./brands/ozyorem_n.jpg",
    },
    {
      id:4,
      image:
        "./brands/yorem_n.jpg",
    },
    {
      id:5,
      image:
        "./brands/zeliva_n.jpg",
    }
  ];
