import React, { useContext, useState } from 'react';
import { languageOptions } from '.';
import { LanguageContext } from './Language';

export default function LanguageSelector(props) {
  const [isActive, setIsActive] = useState(false)
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  // set selected language by calling context methodhandleLanguageChange {userLanguage}
  const handleLanguageChange = e => userLanguageChange(e);

  return (
    <div className='flex'>
      {Object.entries(languageOptions).map(([id, Name]) => (
        <div key={id} className={
          id === userLanguage
            ? "relative cursor-pointer"
            : "hidden"
        } onClick={() => setIsActive(!isActive)}>
          <div
            className={`${props.menu === "true"
              ? "menu-secondary-color"
              : ""
              } w-8`
            }>
            <img src={Name} alt='' />
          </div>
        </div>
      ))}
      {isActive &&
        <div className={`${props.menu === "true"
        ? "top-12 bg-white right-4 grid-cols-1"
        : "top-2 bg-company-primary-color-100 right-28 grid-cols-5"
        } absolute   grid  gap-4 border-2 border-company-primary-color p-2 shadow items-center justify-evenly`}>

          {Object.entries(languageOptions).map(([id, Name]) => (
            <div onClick={() => { handleLanguageChange(id); setIsActive(false); }} key={id} className="w-8">
              <img src={Name} alt='' />
            </div>
          ))}
        </div>}

    </div>
  );
};