import React, { useState } from 'react'
import { ReactComponent as PDFFileIcon } from '../assets/pic/PDFFileIcon.svg';
import FetchPDFFile from '../fetch/useFetchPDFFile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFilePdf, faHourglass, faHourglass1, faSpinner } from '@fortawesome/free-solid-svg-icons';


export default function BillToPDF(props) {
    const [loading, setLoading] = useState(false);

    const makePDF = () => {
        setLoading(true);
        FetchPDFFile(props.invoiceNr) //service for categories
        .then(json => {
            if(json.resCode == 200){

            const fileBase64 = json && json.fileBase64;

            // Decode base64 string
            const byteCharacters = atob(fileBase64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create a URL from the Blob and open it in a new tab
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank', 'noreferrer');
            }else{
                props.showError(true)
                props.setError(json.resMessage);
            }

        })
        .catch(error => {
            props.showError(true)
            props.setError(error);
            console.error('Error fetching PDF file:', error);
        }).finally(a => {
            setLoading(false);
        });
    }

    return (
        <div>
            <button onClick={makePDF} className="flex items-center justify-center hover:opacity-75">
                {
                    loading ?
                    <FontAwesomeIcon color='red' className='animate-spin m-1 w-6 h-6 fill-company-primary-color' icon={faSpinner}/>:
                    <FontAwesomeIcon color='red' className='m-1 w-6 h-6 fill-company-primary-color' icon={faFilePdf}/>
                }
            </button>
        </div>
    )
}
