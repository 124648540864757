import React from "react";
import CounterBtn from "./CounterBtn";

export default class Counter extends React.Component {

  render() {
    return (
      <div
        className={` ${this.props.basket
          ? ""
          : "absolute top-0 right-1 w-8"
          }
        ${this.props.modal
            ? "border-company-secondary-color"
            : this.props.count
              ? "border-company-secondary-color "
              : "absolute top-0 right-1 w-8"
          } flex  w-full bg-white border-2 rounded-xl shadow-xl`
        }
      >
        <div
          className={`${this.props.basket
            ? "flex-row-reverse"
            : "flex-col"
            } counters  flex w-full  items-center`
          }
        >
          <CounterBtn
            className=""
            onClick={() => this.props.onClickIncrement()}
          >
            <p
              className=" text-3xl flex w-full justify-center items-center h-7"
            >
              &#43;
            </p>
          </CounterBtn>
          <div
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? ""
                : "hidden"
              } ${this.props.modal
                ? "bg-company-secondary-color text-white"
                : this.props.count
                  ? "bg-company-secondary-color text-white"
                  : "bg-white"
              }
          w-full h-full text-center count text-xxs flex items-center justify-center`
            }
          >
            {this.props.modal
              ? <><input
                type="number"
                className=" w-14 h-1 h-3 h-6 bg-company-secondary-color border-0 font-normal text-right outline-none"
                value={this.props.count
                  ? this.props.count
                  : ""
                }
                onChange={(e) => this.props.handleChangeCount(e, this.props.item)}
              />
              </>
              : <>
                {this.props.count
                  ? this.props.count
                  : 0
                }
              </>
            }
          </div>

          <CounterBtn
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? " "
                : "hidden"
              }`
            }
            onClick={() => this.props.onClickDecrement()}
          ><p
          className=" text-3xl flex w-full justify-center items-center  h-7"
        >
            -
            </p>
          </CounterBtn>
        </div>
      </div>
    );
  }
}



export class Counter2 extends React.Component {

  render() {
    return (
      <div
        className={` ${this.props.basket
          ? "w-full"
          : "absolute top-1 right-1 w-full"
          }
        ${this.props.modal
            ? "border-company-secondary-color"
            : this.props.count
              ? "border-company-secondary-color "
              : "absolute top-1 right-1 w-8"
          } flex  w-full bg-white border-2 border-slate-100 rounded-lg`
        }
      >
        <div
          className={`${this.props.basket
            ? "flex-row-reverse"
            : "flex-col"
            } counters  flex w-full  items-center`
          }
        >
          <div className="px-1">
          <CounterBtn
            className=""
            onClick={() => this.props.onClickIncrement()}
          >
            <p
              className=" text-3xl flex w-full justify-center items-center h-7"
            >
              &#43;
            </p>
          </CounterBtn>
          </div>

          <div
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? ""
                : "hidden"
              } ${this.props.modal
                ? ""
                : this.props.count
                  ? ""
                  : "bg-white"
              }
          w-full h-full text-center count text-xxs flex items-center justify-center px-1`
            }
          >
            {this.props.modal
              ? <><input
                type="number"
                className=" w-14 h-1 h-3 h-6 bg-company-secondary-color border-0 font-normal text-right outline-none"
                value={this.props.count
                  ? this.props.count
                  : ""
                }
                onChange={(e) => this.props.handleChangeCount(e, this.props.item)}
              />
              </>
              : <>
                {this.props.count
                  ? this.props.count
                  : 0
                }
              </>
            }
          </div>

          <div className="px-1">
          <CounterBtn
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? " "
                : "hidden"
              }`
            }
            onClick={() => this.props.onClickDecrement()}
          ><p
          className=" text-3xl flex w-full justify-center items-center  h-7"
        >
            -
            </p>
          </CounterBtn>
          </div>

        </div>
      </div>
    );
  }
}


export  class Counter3 extends React.Component {

  render() {
    return (
      <div
        className={` ${this.props.basket
          ? ""
          : "absolute top-1 right-1 w-8"
          }
        ${this.props.modal
            ? "border-company-secondary-color"
            : this.props.count
              ? "border-company-secondary-color "
              : "absolute top-1 right-1 w-8"
          } flex  w-full bg-white border-2 rounded-xl shadow-xl`
        }
      >
        <div
          className={`${this.props.basket
            ? "flex-row-reverse"
            : "flex-col"
            } counters  flex w-full  items-center`
          }
        >
          <CounterBtn
            className=""
            onClick={() => this.props.onClickIncrement()}
          >
            <p
              className=" text-3xl flex w-full justify-center items-center h-7"
            >
              &#43;
            </p>
          </CounterBtn>
          <div
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? ""
                : "hidden"
              } ${this.props.modal
                ? "bg-company-secondary-color text-white"
                : this.props.count
                  ? "bg-company-secondary-color text-white"
                  : "bg-white"
              }
          w-full h-full text-center count text-xxs flex items-center justify-center`
            }
          >
            {this.props.modal
              ? <><input
                type="number"
                className=" w-14 h-1 h-3 h-6 bg-company-secondary-color border-0 font-normal text-right outline-none"
                value={this.props.count
                  ? this.props.count
                  : ""
                }
                onChange={(e) => this.props.handleChangeCount(e, this.props.item)}
              />
              </>
              : <>
                {this.props.count
                  ? this.props.count
                  : 0
                }
              </>
            }
          </div>

          <CounterBtn
            className={`${this.props.modal
              ? ""
              : this.props.count
                ? " "
                : "hidden"
              }`
            }
            onClick={() => this.props.onClickDecrement()}
          ><p
          className=" text-3xl flex w-full justify-center items-center  h-7"
        >
            -
            </p>
          </CounterBtn>
        </div>
      </div>
    );
  }
}
