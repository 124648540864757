import tr from './tr.json';
import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import nl from './nl.json';

import FlaggeIconTurk from '../assets/pic/tr.png';
import FlaggeIconGerman from '../assets/pic/de.png';
import FlaggeIconBritain from '../assets/pic/gb.png';
import FlaggeIconFrance from '../assets/pic/fr.png';
import FlaggeIconNetherlands from '../assets/pic/nl.png';

export const dictionaryList = { tr, en, de, fr, nl };

export const languageOptions = {
  tr: FlaggeIconTurk ,
  en: FlaggeIconBritain ,  
  de: FlaggeIconGerman,
  fr: FlaggeIconFrance,
  nl: FlaggeIconNetherlands
};