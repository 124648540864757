import React from 'react'
import { useEffect, useState } from 'react'
import { Text } from '../languages/Language';

const USER_CONSENT_COOKIE_KEY = 'cookie_consent_is_true'
const USER_CONSENT_COOKIE_EXPIRE_DATE = 365


export default function CookieBanner(props) {
    const [cookieConsentIsTrue, setCookieConsentIsTrue] = useState(true)

    useEffect(() => {

        const consentIsTrue = getWithExpiry(USER_CONSENT_COOKIE_KEY) === 'true'
        setCookieConsentIsTrue(consentIsTrue)
    }, [])

    function setWithExpiry(key, value, ttl) {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() < item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    const onClick = (e) => {
        e.preventDefault()
        console.log('Cookie Consent accepted')

        if (!cookieConsentIsTrue) {
            setWithExpiry(USER_CONSENT_COOKIE_KEY, 'true', USER_CONSENT_COOKIE_EXPIRE_DATE)
            setCookieConsentIsTrue(true)
        }
    }

    function openDatenschutz() {
        console.log("hi")
        props.ShowDatenschutz(true)

    };

    if (cookieConsentIsTrue) {
        return null
    }

    return (
        <div className='flex  justify-between items-center w-full h-12 bottom-16 absolute transition transform duration-75
                        ease-in shadow-xl shadow-white px-2 py-1   bg-company-primary-color/75'>
            <p className="text-sm font-medium">
                <Text tid={"CookieText"} />
                {' '}
                <button
                    onClick={() => openDatenschutz()}
                    className="text-sm underline hover:text-lightAccent">
                    <Text tid={"ContinueReading"} />
                </button>
            </p>
            <div className="flex items-center">
                <button
                    className="flex items-center p-2 text-sm w-8 justify-center font-bold text-white bg-slate-600 whitespace-nowrap rounded-full"
                    onClick={e => onClick(e)}
                >
                    &#10006;
                </button>
            </div>
        </div>
    )
}
