import React from 'react'
import {URL as NEWURL} from '../constants';
import {VENDORID as NEWVENDORID} from '../constants';
import { ReactComponent as SaveIcon } from '../assets/pic/save.svg';

export default function SlipSave(props) {

    function onSlipSave() {

        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": 2012, //Slip Save
                "token": sessionStorage.getItem('token'),
                "slipRef": sessionStorage.getItem('slipRef'),
                "vendorId": NEWVENDORID,
                "erpGenExpl1": sessionStorage.getItem('Expl1') ? sessionStorage.getItem('Expl1') : "",
                "erpGenExpl2": sessionStorage.getItem('Expl2')? sessionStorage.getItem('Expl2') : ""
            })
        };

        const fetchData = async () => {
            try {
                const response = await fetch(url, requestOptions);
                const json = await response.json();
                if(json.resCode === 200){
                    sessionStorage.setItem('slipRef', json.dataSet[0]?.slipRef);
                    sessionStorage.removeItem('Expl1');
                    sessionStorage.removeItem('Expl2');

                     props.onSlipDeleteAllLines()
                    }
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    }

    return (
        <>
            <button
                className="h-8 w-8 flex items-center justify-center rounded-md border border-transparent bg-company-primary-color p-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                type="button"
                onClick={() => onSlipSave()}
            >
                <SaveIcon className="w-4 h-4 fill-white"/>
            </button>
        </>
    )
}