import React from 'react'
import { Text } from '../languages/Language';

function DetailedBasket(props) {

    return (
            <div>
                <button
                    onClick={() => props.ShowDetailedBasket(true)}
                    className="flex py-1 px-3 w-auto min-w-[8rem] items-center justify-center rounded-md
                    border border-transparent bg-company-primary-color text-base font-medium
                  text-white shadow-sm hover:opacity-75"
                >
                    <Text tid="GoToCash" />
                </button>
            </div>
    )
}

export default DetailedBasket